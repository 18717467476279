.card{
  border: 1px solid silver!important;
  border-radius: 4px!important;
}
.carousel, .carousel-inner {
  transition: none !important;
}
.card-header{
  background-color: #4a50a0;
  color: white;
  font-weight: 700;
}
.linearAlphabets div {
  color:#4a50a0;
  font-size:32px;
  font-weight:700;
  padding:12px;
}
.linearAlphabets{
  display: flex;
flex-wrap:wrap;
}