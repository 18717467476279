body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.banner {
  align-items: center;
  position: relative;
  display: flex;
  width: 100%;
}
.bannerText {
  position: absolute;
  width: 40%;
  margin-left: 5%;
}
@media (max-width: 768px) {
  .bannerText{
    width: 95%;
  }
}
.bannerImage {
  width: 100%;
}
.bannerImage img {
  width: 100%;
}
.bannerMainText {
  font-size: clamp(
    1.3rem,
    2.3vw,
    3rem
  ); /* Minimum: 1.5rem, Preferred: 3vw, Maximum: 3rem */
  font-weight: bold;
  color: #002d62;
  margin-bottom: 1rem;
}

/* Subtext */
.bannerSubText {
  font-size: clamp(1.2rem, 2vw, 1rem); /* Adjusts font size dynamically */
  line-height: 1.5;
  color: #004080;
  margin-bottom: 0.5rem;
}

@media (max-width: 480px) {
 .navbar-brand img{
  width: 330px!important;
 }
}
.clsDivMaincat {
  color: blue;
    font-weight: 500;
    height: 30px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    /* border: 2px solid black; */
    margin: 5px;
    border-radius: 5px;
    align-items: center;
    display: flex
;
    justify-content: center;
}

.h-divider {
  margin: auto;
  /* width: 80%; */
  position: relative;
}

.h-divider .shadoww {
  overflow: hidden;
  height: 20px;
}

.h-divider .shadoww:after {
  content: '';
  display: block;
  margin: -25px auto 0;
  width: 100%;
  height: 25px;
  border-radius: 125px/12px;
  box-shadow: 0 0 8px black;
}