.footer-logo img {
    max-width: 100%;
    height: auto;
  }
  
  .footer-social-links a {
    color: #ffffff;
    transition: color 0.3s ease;
  }
  
  .footer-social-links a:hover {
    color: #0a66c2; /* LinkedIn blue */
  }
  
  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    /* padding-top: 10px; */
  }
  